.coupon-page {
  .privacy-group {
    background: #3f51b521;
    padding: 16px;
    margin: 8px 0;
    label {
      margin-bottom: 0 !important;
    }
  }
  .wrapper-grid {
    .list-item-coupon {
      .item-add-coupon {
        border: 1px dashed #3f51b5;
        border-radius: 8px;
        svg {
          font-size: 2.5em;
        }
        cursor: pointer;
        transition: 0.5 ease-in-out;
        &:hover {
          opacity: 0.7;
        }
      }
      .items {
        padding: 8px;
        min-height: 8em;
      }

      .item-coupon {
        min-height: 8em;
        background-size: 100% 100%;
        display: block;
        .content {
          width: 100%;
          height: 100%;
          // border-left: 4px solid #3ed1bf;
          position: relative;
          .action-group-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: space-between;
            display: none;
            .MuiButton-containedPrimary {
              background-color: #3f51b5d4;
              &:hover {
                background-color: #3f51b5;
              }
            }
          }
          &:hover {
            .action-group-btn {
              display: flex;
            }
          }
        }
        .title-left {
          width: 25%;
          span {
            display: inline-block;
            text-align: center;
            max-height: 100px;
            -webkit-writing-mode: vertical-rl;
            -ms-writing-mode: tb-rl;
            writing-mode: vertical-rl;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            font-size: 100%;
            line-height: 1.3em;
            text-transform: uppercase;
          }
        }
        .content-right {
          width: 75%;
          padding-left: 1.3em;
          padding-right: 16px;
          .overflow-e {
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
