#userProfile-page {
  .MuiAvatar-circular {
    width: 100%;
    height: inherit;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    img {
      position: absolute;
      object-fit: cover;
    }
  }
  .content {
    border-left: 1px solid #f3f3f3;
    padding: 0 3em;
  }
}
