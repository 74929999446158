#login-page {
  background-size: cover;
  background-origin: center;
  background-repeat: no-repeat;
  .login-box {
    @media screen and (max-width: 1024px) {
      width: 55%;
    }
    width: 33%;
  }
  .login-box-body {
    border-radius: 6px;
    box-shadow: 4px 6px 14px #adacac70;
    background: rgba(255, 255, 255, 0.267);
  }
}
