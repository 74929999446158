.quantity-box {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  button {
    min-width: 50px;
    border-right: 1px solid #c4c4c4;
    border-radius: 0;
    &:last-child {
      border-right: 0;
      border-left: 1px solid #c4c4c4;
    }
  }
  input {
    border: none;
    min-width: 4em;
  }
}
