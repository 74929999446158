@import "./_b-color.scss";

.help-block {
  font-size: 14px;
  margin-top: 4px;
}
.alert {
}
.alert-success {
}
.alert-danger {
  color: $color_err_text;
}
.alert-warning {
}
.alert-info {
}

.btn-primary {
  border: 2px solid $primary_color;
  height: auto;
  padding: 8px 16px;
  border-radius: 6px;
}
.btn-primary-filled {
  color: #f4f2ef;
  border: 2px solid $primary_color;
  background: $primary_color;
  height: auto;
  padding: 8px 16px;
  border-radius: 6px;
  box-shadow: 3px 3px 9px -1px #565656b5;
  &:hover {
    cursor: pointer;
  }
}

.btn-secondary {
  color: #f75b37;
  border: 2px solid #f75b37;
  height: auto;
  padding: 8px 16px;
  border-radius: 6px;
}
.btn-secondary-filled {
  color: #242e52;
  border: 2px solid #f75b37;
  background: #f75b37;
  height: auto;
  padding: 8px 16px;
  border-radius: 6px;
}

.form-group {
  .label-input {
    margin-bottom: 4px;
    display: inline-block;
    &.text-bold {
      font-weight: 600;
    }
  }
  .icon-info,
  .color-blue {
    color: $primary_color;
  }
  .icon-info {
    margin-right: 4px;
  }
}
.app-content-wrapper {
  height: calc(100vh - 73px);
  overflow: auto;
}
.header-content,
.wrapper-paper {
  background: white;
  margin-bottom: 16px;
  padding: 16px;
  box-shadow: 0px 3px 9px -1px #d2d0d0b5;
  border-radius: 8px;
}

// table custom
.centeredHeaderCell div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center; /* center, flex-start (left), flex-end(right) */
}

.rightHeaderCell div {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center; /* center, flex-start (left), flex-end(right) */
}

.centeredHeaderCell div button {
  margin-right: 0;
  margin-left: 0px; /* was previously -8px so not quite centered */
}
.btn-changelanguage {
  font-size: 24px;
  padding: 8px;
  margin-left: 4px;
}
.avatar {
  height: 40px;
  width: 40px;
  min-width: unset;
  overflow: hidden;
  border-radius: 50px;
  background: white;
  margin-left: 8px;
  img {
    width: 100%;
    height: 100%;
  }
}

.MuiTableCell-head {
  white-space: nowrap !important;
}
.wrapper-table {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 9px -1px #d2d0d0b5;
  .title-table {
    border-left: 4px solid #3f51b5;
    padding: 0 8px;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 18px;
  }
  thead .MuiButton-label {
    white-space: nowrap;
  }
  thead tr th {
    white-space: nowrap !important;
  }
  .MuiTableCell-head {
    white-space: nowrap !important;
  }
  .search-table {
    margin-bottom: 20px;
    button {
      height: 42px;
    }
  }
  .MuiToolbar-regular {
    .MuiInput-underline::before {
      content: "\00a0";
      top: 0;
      border: 2px solid rgba(0, 0, 0, 0.42);
      border-radius: 8px;
    }
    .MuiInputBase-input {
      padding: 12px 10px 12px;
    }

    .MuiInput-underline:after {
      top: 0;
      border: 2px solid #3f51b5;
      border-radius: 8px;
    }
  }
  .MuiTablePagination-root {
    .MuiInputBase-input {
      padding: 12px 24px 12px 8px !important;
    }
  }
}
.MuiSelect-select.MuiSelect-select {
  padding: 15.5px 24px !important;
}

// checkbox switch
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.react-tel-input .form-control {
  font-size: 16px !important;
  height: 42px !important;
  width: 100% !important;
}

.react-tel-input .selected-flag {
  width: 42px !important;
}

.custom-date-picker {
  .MuiFormControl-root > label {
    display: none;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0 !important;
  }
  .MuiOutlinedInput-input {
    padding: 11.5px 14px !important;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    width: 100%;
  }
  .MuiOutlinedInput-notchedOutline {
    top: -1px !important;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-left: 0 !important;
    input {
      padding: 11.5px 0 !important;
    }
  }
  &.add-padding .MuiOutlinedInput-adornedEnd {
    padding-left: 14px !important;
  }
}

.text-error {
  color: red;
  font-size: 15px;
}

.input-require {
  &::before {
    content: "*";
    color: red;
  }
}

.custom-select {
  height: 42px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  width: 100%;
  &.MuiInput-underline:before {
    border-bottom: none !important;
  }
  select {
    padding-left: 10px;
  }
}

.modal-custom {
  background: white;
  border-radius: 8px;
  width: 40%;
  position: absolute;
  overflow: auto;
  max-height: 100%;
  &:focus {
    outline: none;
  }
}

.MuiBackdrop-root {
  background-color: #00000040 !important;
}

.modal-head {
  padding: 16px;
  background: #303d88e3;
  color: white;
}
.modal-content {
  padding: 16px;
}

.centeredHeaderCell span {
  justify-content: center; /* center, flex-start (left), flex-end(right) */
  button {
    margin-right: 0;
    margin-left: 0px; /* was previously -8px so not quite centered */
  }
}

.rightHeaderCell span {
  justify-content: flex-end; /* center, flex-start (left), flex-end(right) */
  button {
    margin-right: 0;
    margin-left: 0px; /* was previously -8px so not quite centered */
  }
}

.react-tel-input {
  input:disabled {
    background: #fafafa;
  }
}

.loading-page {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.loading-wheel {
  width: 20px;
  height: 20px;
  margin-top: -40px;
  margin-left: -40px;

  position: absolute;
  top: 50%;
  left: 50%;

  border-width: 30px;
  border-radius: 50%;
  -webkit-animation: spin 1.2s linear infinite;
}
.style-2 .loading-wheel {
  border-style: double;
  border-color: #ccc transparent;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

// advance search
.wrapper-advance-search {
  .advance-search-box {
    .form-group {
      display: flex;
      align-items: center;
      label {
        margin-bottom: 0;
      }
    }
  }
}

// custom-date-picker-group
.custom-date-picker-group {
  select {
    min-height: 42px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 4px;
  }
}

.icon-help {
  margin-left: 4px;
  color: $border-grey;
  cursor: help;
}

.custom-tabs {
  background: $color_base_text !important;
  .custom-tab-content {
    padding: 16px 24px;
    font-size: 15px;
  }
}

.wrapper-input-des {
  display: flex;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  overflow: hidden;
  span {
    padding: 0 16px;
    display: flex;
    align-items: center;
    border-left: 1px solid #c4c4c4;
    background: #f9f9f9;
  }
  input {
    border: none;
  }
}

.wrapper-image-upload {
  height: 160px;
  border: 1px dashed #c4c4c4;
  border-radius: 4px;
  position: relative;
  .btn-uploadimage {
    width: 100%;
    height: 100%;
    svg {
      font-size: 56px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .image-item__btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.wrapper-time-range-picker {
  display: flex;
  justify-content: space-between;
  min-width: 20em;
  align-items: center;
  .MuiTextField-root {
    width: 100%;
  }
  & > span {
    padding: 16px;
  }
}
