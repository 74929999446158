.loyalty-tier-rule-page {
  // font-size: 14px;

  .tier-rule-info {
    background: $white;
    border-radius: 8px;
    box-shadow: 0px 3px 9px -1px #d2d0d0b5;

    .tier-rule-accordion-details {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
    }
    .tier-rule-accordion-title {
      font-size: 18px;
      font-weight: 600;
      color: $color_base_text;
    }

    .expand-icon {
      width: 1.2em;
      height: 1.2em;
      color: $color_base_text;
    }

    .tier-rule-accordion {
      padding: 6.5px 16px;
      &.Mui-expanded {
        background: $color_base_text;
        .tier-rule-accordion-title {
          color: $white;
        }
        .expand-icon {
          color: $white;
        }
        .MuiIconButton-root {
          padding: 0;
        }
      }
      .Mui-expanded {
        margin: 0 !important;
      }
    }

    .info-item {
      &:not(:last-child) {
        border-bottom: 1px solid $border-grey;
        padding-bottom: 16px;
      }
      &:not(:first-child) {
        margin-top: 16px;
      }
      &__title {
        font-weight: 600;
        color: $color_base_text;
      }
      &__description {
        .tier-line {
          &:not(:last-child) {
            margin-bottom: 8px;
          }
          &__link {
            color: $light_blue;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
              opacity: 0.8;
            }
            svg {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }
}
.loyalty-point-rule-page {
  // font-size: 14px;
  .point-rule-tabs {
    background: $white;
    border-radius: 8px;
    box-shadow: 0px 3px 9px -1px #d2d0d0b5;

    .point-rule-tabs-header {
      background: transparent;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      box-shadow: 0px 3px 9px -1px #d2d0d0b5 !important;
      .MuiTab-root {
        padding: 12px;
      }
      .MuiTab-wrapper {
        text-transform: initial;
        color: $grey_color;
        font-size: 18px;
      }
      .MuiTabs-indicator {
        background-color: $white;
        height: 3px;
      }
      .Mui-selected {
        background: $color_base_text;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .MuiTab-wrapper {
          color: $white;
          font-weight: 600;
        }
      }
    }
    .point-rule-settings {
      .setting-item {
        &:not(:last-child) {
          border-bottom: 1px solid $border-grey;
          padding-bottom: 16px;
        }
        &:not(:first-child) {
          margin-top: 16px;
        }
      }

      .addon {
        width: 100%;
        height: 100%;
        background: $bg_color;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
      }
    }
  }
  // .custom-radio,
  // .custom-checkbox {
  //   .MuiTypography-body1 {
  //     font-size: 14px;
  //   }
  // }
  // .custom-select {
  //   font-size: 14px;
  // }
}
