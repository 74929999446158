.custom-date-range {
  .wrapper {
    min-height: 42px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px 10px;
    width: 100%;
    cursor: pointer;
  }
  .wrapper-date {
    position: relative;
    > div {
      position: absolute !important;
      z-index: 9999;
      right: 0;
      top: 8px;
    }
  }
}
