@import "./_b-color.scss";

body {
  color: $color_base_text;
  letter-spacing: 0.04em;
  background: $bg_color;
}
html,
body {
  width: 100%;
  height: 100vh;
  scroll-behavior: smooth;
  overflow: hidden;
}

* {
  scrollbar-width: 4px;
  scrollbar-width: thin;
  scrollbar-color: $primary_color #f1f6f77c;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  background: #f1f6f77c;
}
*::-webkit-scrollbar-thumb {
  background-color: $primary_color;
  border-radius: 50px;
}

* {
  margin: 0;
  padding: 0;
  text-rendering: auto;
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

A {
  text-decoration: none;
  color: inherit;
}

IMG[src=""] {
  visibility: hidden;
  width: 0;
  height: 0;
}

IMG,
TABLE,
FIELDSET {
  border: 0;
}

OL,
UL {
  list-style-type: none;
}

LEGEND {
  display: none;
}

BUTTON,
INPUT {
  border-radius: 4px;
  outline: 0 none;

  border: 1px solid $primary_border;

  &[type="search"]::-webkit-search-decoration,
  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-results-button,
  &[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }
  &.disable {
    background: #fafafa;
  }
}

TEXTAREA {
  border-radius: 4px;
  outline: 0 none;
  border: 1px solid #c4c4c4;
  padding: 8px;
  width: 100%;
}

BUTTON {
  background: {
    color: transparent;
    image: none;
  }
  overflow: visible;
  cursor: pointer;
  border: 0 none;

  &:focus {
    outline: 0 none;
  }
}

TEXTAREA {
  resize: none;
}

HR {
  color: black;
  background-color: black;
  border: none;
}

MARK {
  color: inherit;
  background-color: transparent;
}

// table
.MuiPaper-rounded {
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: none !important;
}
.MUIDataTableSearch-main-28 {
  align-items: center;
}
