.header-main {
  height: 73px;
  background: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
  box-shadow: 0px 3px 9px -1px #d2d0d0b5;
  position: relative;
  z-index: 9;
  .hamberger-btn {
    height: 40px;
    width: 40px;
    min-width: unset;
    padding: 11px;
    border-radius: 50px;
    background: white;
    margin-left: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
