@import "../../base/b-color";

$sidebar-bg-color: #333547 !default;
$sidebar-color: rgba(255, 255, 255, 0.897) !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b00 !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: transparent !default;
$icon-size: 24px !default;

$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
