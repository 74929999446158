.trigger-campaign-page {
  .title {
    border-left: 4px solid #3f51b5;
    padding: 0 8px;
    font-weight: 600;
    font-size: 18px;
  }
  .wrapper-campaign-box {
    background: white;
    padding: 24px 16px 16px 16px;
    border-radius: 8px;
    box-shadow: 0px 3px 9px -1px #d2d0d0b5;
    display: flex;
    align-items: start;
    position: relative;
    overflow: hidden;
    height: 100%;
    .inf {
      padding: 0 0 0 16px;
      width: 80%;
      h3 {
        font-weight: bold;
        font-size: 18px;
      }
      a {
        color: #3f51b5;
        transition: 0.1s;
        &:hover {
          opacity: 0.9;
        }
      }
    }
    .stt {
      position: absolute;
      top: 0;
      right: 0;
      padding: 2px 12px;
      border-radius: 0px 0px 0px 8px;
      font-size: 15px;
      color: white;
      &.active {
        background: #1abc9c;
      }

      &.in-active {
        background: #cccccc;
      }
    }
    .ic-box {
      padding: 8px;
      background: #3f51b5;
      width: 20%;
      position: relative;
      border-radius: 8px;
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 2rem;
        color: white;
      }
    }
  }
}
