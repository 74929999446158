.custom-group-date-picker {
  select {
    height: 42px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    padding: 0 6px;

    font-size: 16px;

    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="gray"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>')
      100% 50% no-repeat transparent;
    background-position-x: 97%;
    background-position-y: 8px;

    padding: 0 1rem;
    padding-right: 2rem;
  }
  .disabled {
    pointer-events: none;
    cursor: default;
  }
}
