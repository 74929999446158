.dashboard-page {
  .dashboard-block {
    padding: 16px;
    position: relative;
    box-shadow: 0px 3px 9px -1px #d2d0d0b5;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .dashboard-store-information {
    padding: 0;
    .card {
      position: relative;

      background: $light_purple;
      color: $grey;
      font-size: 13px;
      padding: 16px 0px 16px 16px;
      border-radius: 8px;
      .card-top {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 16px;
      }
      .card-item {
        display: flex;
        &.card-item-icon {
          justify-content: center;
          align-items: center;
          background-color: $light_grey;

          margin-right: 24px;
          width: 56px;
          height: 52px;

          padding: 6px;
          border-radius: 6px;
          img {
            width: 36px;
            height: auto;
          }
        }
        &.card-item-info {
          display: flex;
          flex-direction: column;
          .item-title-percentage {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            .title {
              font-size: 16px;
              color: $white;
              text-transform: uppercase;
            }
            .percentage {
              .ribbon {
                padding: 4px 16px 4px 36px;
                position: absolute;
                right: 0;
                top: 14px;

                height: 28px;

                color: $white;
                font-size: 14px;
                -webkit-clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 24% 50%, 6% 0);
                clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 100%, 24% 50%, 6% 0);

                &.ribbon-orders {
                  background-color: $light_green;
                }
                &.ribbon-revenue {
                  background-color: $light_red;
                }
                &.ribbon-average {
                  background-color: $light_blue;
                }
              }
            }
          }
          .item-info-result {
            font-size: 22px;
            display: flex;
            align-items: center;
            .result {
              color: $core_black;
              margin-right: 20px;
            }
            .icon-up {
              color: $light_green;
            }
            .icon-down {
              color: $light_red;
            }
          }
        }
      }
      .card-bottom {
        padding-right: 16px;
        display: flex;
        justify-content: space-between;
        color: $white;
        .time-before {
          font-size: 14px;
        }
        .icon-right {
          width: 0.8em;
          height: 0.8em;
        }
      }
    }
  }
  .dashboard-store-promotions {
    background: $white;
    border-radius: 8px;
    .store-promotions-title {
      margin-bottom: 12px;
    }
    .store-promotions-list {
      .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-top {
          margin-bottom: 12px;
        }
        &-bottom {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
  .dashboard-store-members {
    background: $white;
    border-radius: 8px;
    .store-members-title {
      margin-bottom: 16px;
    }

    .tag-status-custom {
      padding: 2px 8px;
      min-width: 80px;
      color: $white;
      font-weight: 500;
      text-transform: capitalize;
      &.tag-delivered {
        background: $light_green;
      }
      &.tag-pending {
        background: #d36906;
      }
      &.tag-canceled {
        background: $light_red;
      }
    }
  }

  // common
  .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .loading-wrapper {
    position: absolute;
    top: 50%;
    left: 49%;
  }
}
