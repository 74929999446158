.user {
  .avatar {
    height: 40px;
    width: 40px;
    min-width: unset;
    overflow: hidden;
    border-radius: 50%;
    background: white;
    margin-left: 8px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  P {
    color: $color_base_text;
  }
  .dropdown-user {
    position: absolute;
    background: $white;
    padding: 8px;
    right: 2px;
    margin-top: 6px;
    box-shadow: 0px 3px 9px -1px #d2d0d0b5;
    &::before {
      content: "";
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      position: absolute;
      border-bottom: 10px solid white;
      top: -7px;
      right: 11px;
    }
  }
}
.backdrop-none {
  .MuiBackdrop-root {
    background-color: #00000000 !important;
  }
  .modal-custom {
    box-shadow: 1px 5px 14px 2px #d2d0d0b5 !important;
  }
}
