.member-page {
  .privacy-group {
    background: #3f51b521;
    padding: 16px;
    margin: 8px 0;
    label {
      margin-bottom: 0 !important;
    }
  }
}
.member-detail {
  .MuiAvatar-circular {
    width: 50%;
    height: inherit;
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
    img {
      position: absolute;
      object-fit: cover;
    }
  }

  .wrapper-vip {
    width: 80%;
    background: $secondary_color;
    border-radius: 8px;
    margin: 12px auto;
    color: white;
    padding: 10px 0;
    box-shadow: 0px 3px 9px -1px #d2d0d0b5;
  }
  .header-info {
    border-bottom: 1px solid $color_base_text;
    padding: 8px 0;
  }
  .green-point {
    align-items: center;
    color: green;
    font-weight: 500;
    font-size: 15px;
  }
  .red-point {
    align-items: center;
    color: red;
    font-weight: 500;
    font-size: 15px;
  }
}
