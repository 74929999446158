.spot-page {
  .title-spot {
    padding: 0 8px;
    border-left: 4px solid #242e52;
    margin-top: 32px;
    h3 {
      font-size: 20px;
      font-weight: bold;
    }
  }
}
