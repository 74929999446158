.header-page {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  background: $white;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 3px 9px -1px #d2d0d0b5;
  &.flex-end {
    align-items: flex-end;
  }

  .header-left {
    .header-title {
      font-size: 22px;
      font-weight: bold;
    }
    .header-sub-title {
      font-size: 14px;
    }
    .breadcrumb-wrapper .active {
      cursor: pointer;
    }
  }
  &.dashboard-header {
    position: relative;
  }
}
