$black: #45443f;
$white: #fff;
$core_black: #000000;
$light_purple: #6254e7;
$light_green: #00aaa0;
$light_blue: #0198ff;
$light_red: #f9002d;
$light_grey: #ebebeb;

$primary_color: #3f51b5;
$primary_color_light: #f2f6f6;
$secondary_color: #f75b37;

$border-grey: #90a9a6;
$border-green: #215154;

$grey: #cecece;
$grey_color: #8e8d8d;

$primary_border: #c4c4c4;

$bg_color: #f4f2ef;

$color_base_text: #242e52;
$color_err_text: #e53934;
